import React from "react"
import Seo from "../layout/seo"
import Layout from '../layout/layout';

class NotFoundPage extends React.Component {
    render() {
        return (
        
    <Layout location={this.props.location}>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
    )}
    }

export default NotFoundPage;
